export default function Menu(els) {
  const menu = document.querySelector(".mobile-nav");

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      menu.classList.toggle("hidden");
      menu.classList.toggle("fade-in-nav");
    })
  );
}
