import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";
import Slider from "./js/Slider";
import Menu from "./js/Menu";
import Flickity from "flickity";
import Headroom from "headroom.js";
import lazySizes from "lazysizes";
import Article, { MobileMenu } from "./js/article";

// Only used in dev
if (module.hot) {
  module.hot.accept();
}
let actions = {};
let actionChain = new Map();
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}
function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}
registerAction(Slider);
function runActions() {
  action("Slider", Slider);
  action("Article", Article);
  Menu(document.querySelectorAll(".menu-btn"));
  MobileMenu(document.querySelectorAll(".links-button"));
  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 100,
  });
  headroom.init();

  const fullVideo = document.querySelector(".fullscreen-video");
  const openVideo = document.querySelector(".video-link");
  const closeVideo = document.querySelector(".close-vid");

  if (openVideo) {
    openVideo.addEventListener("click", function () {
        fullVideo.classList.add("open");
        
        // Assuming you have an iframe with class "vimeo-player"
        const vimeoPlayer = document.querySelector(".vimeo-player");
        
        // Modify the src attribute to include autoplay parameter
        vimeoPlayer.src += "&autoplay=1";
    });
  }


  if (closeVideo) {
    closeVideo.addEventListener("click", function () {
        fullVideo.classList.remove("open");
        
        // Assuming you have an iframe with class "vimeo-player"
        const vimeoPlayer = document.querySelector(".vimeo-player");

        // Reset the src attribute to remove autoplay parameter
        vimeoPlayer.src = vimeoPlayer.src.replace("&autoplay=1", "");
    });
  }
  const mobbtn = document.querySelector(".menu-btn");
  const meganav = document.querySelector(".mob-nav");

  const closeBtn = document.querySelector(".close-btn");
  if (closeBtn) {
    closeBtn.addEventListener("click", function () {
      meganav.classList.remove("open");
    });
  }
  mobbtn?.addEventListener("click", function () {
    meganav.classList.toggle("open");
  });
  meganav.querySelectorAll("a").forEach((a) =>
    a.addEventListener("click", () => {
      if (a.href.indexOf("#") !== -1) meganav.classList.toggle("open");
    })
  );
  document
    .querySelectorAll(".dropdown-toggle")
    .forEach((tog) =>
      tog.addEventListener("click", (e) =>
        tog
          .closest(".dropdown")
          .querySelector(".dropdown-content")
          .classList.toggle("hidden")
      )
    );

  
}
runActions();

var elem = document.querySelector(".main-carousel");
new Flickity(elem, {
  // options
  cellAlign: "left",
  contain: true,
});

function handleScroll() {
  // Define selectors.
  let parent = document.querySelector(".parent");
  let child = parent.querySelector(".child");
  let parentTop = parent.getBoundingClientRect().top || 0;
  let crossChildHeight = 0;
  if (child.clientHeight > parent.getBoundingClientRect().bottom) {
    crossChildHeight =
      parent.getBoundingClientRect().bottom - child.clientHeight;
  }
  if (parseInt(crossChildHeight) < 0) {
    parent.classList.remove("fixed");
    parent.classList.add("absolute");
  } else {
    parent.classList.remove("absolute");
    if (parentTop <= crossChildHeight) {
      parent.classList.add("fixed");
    } else {
      parent.classList.remove("fixed");
    }
  }
}
document.addEventListener("scroll", handleScroll);
