import Flickity from "flickity-fade";
// import Flickity from "flickity-fade";
import eventBus from "./utils/eventBus";

function Slider(el) {
  el.classList.remove("hidden");
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  const indexEl = el.parentElement.querySelector("[data-index]");

  let flk = new Flickity(el, options);

  var nav = document.getElementById('custom-nav');

  const baseNavigationElement = document.getElementById("base-navigation");

  flk.cells.forEach(function(cell, i) {
    const title = cell.element.getAttribute('data-title');
    var button = baseNavigationElement.cloneNode(true);
    button.id = 'slider-nav-' + i;

    if (i != 0 ) {
      button.classList.add('opacity-50');
    }

    button.classList.remove("hidden");
    const buttonTitle = button.querySelector('p');
    buttonTitle.innerText = title;
    button.addEventListener('click', function() {
      flk.select(i);
    });
    nav.appendChild(button);
  });

  // create onChange event to update opacity of selected and not selected buttons
  flk.on('change', function(index) {
    const buttons = nav.querySelectorAll('.base-navigation');
    buttons.forEach(function(button, i) {
      if (i != index) {
        button.classList.add('opacity-50');
      } else {
        button.classList.remove('opacity-50');
      }
    });
  });


  function prevSlide() {
    flk.previous();
    console.log("Prev slide");
  }

  function nextSlide() {
    flk.next();
    console.log("testslider");
  }

  const prev = document.querySelector(options.prevButton);
  if (prev) {
    prev.addEventListener("click", prevSlide);
  }
  const next = document.querySelector(options.nextButton);
  if (next) {
    next.addEventListener("click", nextSlide);
  }

  flk.on("change", (index) => {
    if (indexEl) indexEl.innerText = index + 1;
  });

  eventBus.on("slider-reinit", () => {
    // flk = new Flickity(el, options);
    flk.reposition();
    console.log("repo");
  });
}

export default Slider;
