import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
gsap.registerPlugin(ScrollTrigger);

export default function Article(el) {
  const sections = [...el.querySelectorAll("[data-article] > article")];
  const nav = el.querySelector("[data-article-nav]");

  sections.forEach((section, index) => {
    const link = document.createElement("a");
    const counterSpan = document.createElement("span");
    counterSpan.innerText = `#${String(index + 1).padStart(2, '0')}`;

    link.className = "flex flex-row justify-start";
    counterSpan.style.minWidth = '75px';
    counterSpan.style.maxWidth = '75px';
    counterSpan.style.display = 'inline-block';

    link.appendChild(counterSpan);
    link.innerHTML = counterSpan.outerHTML + section.dataset.title;
    link.style.marginLeft = '-78px'
    link.href = "#/";
    nav.appendChild(link);

    ScrollTrigger.create({
      trigger: section,
      //   trigger viewport
      start: "top center",
      end: "bottom center",
      // markers: true,
      onEnter: () => {
        setActive(section);
      },
      onEnterBack: () => {
        setActive(section);
      },
    });
  });

  function setActive(section) {
    const index = sections.indexOf(section);
    const children = nav.children;

    for (var i = 0; i < children.length; i++) {
      children[i].classList.remove("active");
    } 
    nav.children[index+1].classList.add("active");
  }

  nav.querySelectorAll("a").forEach((link) =>
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const index = [...nav.children].indexOf(e.target);
      sections[index-1].scrollIntoView({
        behavior: "smooth",
      });
    })
  );
}

export function MobileMenu(els) {
  const innerNav = document.querySelector(".inner-nav");
  let isNavOpen = false;

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      
      if (!isNavOpen) {
        isNavOpen = true;
        innerNav.classList.remove("hidden");
        gsap.fromTo(innerNav, { opacity: 0 }, { opacity: 1, duration: 0.2 });
      } else {
        isNavOpen = false;
        gsap.fromTo(
          innerNav,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
            onComplete: () => innerNav.classList.add("hidden"),
          }
        );
      }
    })
  );
}

document.querySelectorAll('[data-article] table').forEach((table) => {
  const wrapper = document.createElement('div');
  wrapper.className = 'table-wrap';
  table.parentNode.insertBefore(wrapper, table);
  wrapper.appendChild(table);
});


